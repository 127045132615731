export default [
  {
    title: 'Genel Durum',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'DASHBOARD',
    action: 'read',
  },
  {
    title: 'Sıfır Satış',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Genel Bakış',
        route: 'SaleOverviews',
        resource: 'RP_SALES',
        action: 'read',
      },
      {
        title: 'Anlık Satışlar',
        route: 'SaleBrands',
        resource: 'RP_SALES',
        action: 'read',
      },
      {
        title: 'Hedef Gerçekleştirme',
        route: 'SaleGoals',
        resource: 'RP_SALES',
        action: 'read',
      },
      {
        title: 'Kümülatif',
        route: 'SaleCumulatives',
        resource: 'RP_SALES',
        action: 'read',
      },
      {
        title: 'Satışlar',
        route: 'SaleCounts',
        resource: 'RP_SALES',
        action: 'read',
      },

      {
        title: 'Bağlantılar',
        route: 'SaleConnections',
        resource: 'RP_SALES',
        action: 'read',
      },
      {
        title: 'Stoklar',
        route: 'SaleStocks',
        resource: 'RP_SALES',
        action: 'read',
      },
      {
        title: 'Takaslar',
        route: 'SaleSwaps',
        resource: 'RP_SALES',
        action: 'read',
      },
      // {
      //   title: 'Sigorta',
      //   route: 'SaleInsurances',
      //   resource: 'RP_SALES',
      //   action: 'read',
      // },
      {
        title: 'Görüşmeler',
        route: 'SaleInterview',
        resource: 'RP_SALES',
        action: 'read',
      },
      {
        title: 'Müşteriler',
        route: 'SaleCustomers',
        resource: 'RP_SALES',
        action: 'read',
      },
    ],
  },
  {
    title: 'İkinci El Satışlar',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Genel Bakış',
        route: 'I2Sales',
        resource: 'RP_SALE_I2SALES',
        action: 'read',
      },
      {
        title: 'Anlık Satışlar',
        route: 'I2SalesBrands',
        resource: 'RP_SALE_I2SALES',
        action: 'read',
      },
      {
        title: 'Kümülatif',
        route: 'I2SaleCumulatives',
        resource: 'RP_SALE_I2SALES',
        action: 'read',
      },
      // {
      //   title: 'Rac - Takas - Dış Alım',
      //   route: 'I2BuyTypes',
      //   resource: 'RP_SALE_I2SALES',
      //   action: 'read',
      //   disabled: true,
      // },
      {
        title: 'Takaslar',
        route: 'I2Swaps',
        resource: 'RP_SALE_I2SALES',
        action: 'read',
      },
      // {
      //   title: 'Stoklar',
      //   route: 'ProfitabilityReport',
      //   resource: 'RP_SALE_I2SALES',
      //   action: 'read',
      //   disabled: true,
      // },
      // {
      //   title: 'Brüt Kar Marjı',
      //   route: 'ProfitabilityReport',
      //   resource: 'RP_SALE_I2SALES',
      //   action: 'read',
      //   disabled: true,
      // },
      // {
      //   title: 'Stok Devir Hızı',
      //   route: 'ProfitabilityReport',
      //   resource: 'RP_SALE_I2SALES',
      //   action: 'read',
      //   disabled: true,
      // },
    ],
  },
  // {
  //   title: 'Filo Kiralama',
  //   icon: 'FileTextIcon',
  //   disabled: true,
  //   children: [
  //     {
  //       title: 'Genel Bakış',
  //       route: 'Rentals',
  //       resource: 'RP_RENTAL',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Aylık Kiralama',
  //       route: 'RentalsMonthly',
  //       resource: 'RP_RENTAL',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Çalışan Araçlar',
  //       route: 'RentalsCumulatives',
  //       resource: 'RP_RENTAL',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Markalar',
  //       route: 'RentalsBrands',
  //       resource: 'RP_RENTAL',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Yeni Araç Alımları',
  //       route: 'RentalsBuyCars',
  //       resource: 'RP_RENTAL',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Stok Durumları',
  //       route: 'RentalsStocks',
  //       resource: 'RP_RENTAL',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Dönen Araçlar',
  //       route: 'RentalsSales',
  //       resource: 'RP_RENTAL',
  //       action: 'read',
  //     },
  //   ],
  // },
  // {
  //   title: 'Servis',
  //   icon: 'SettingsIcon',
  //   resource: 'RP_SERVICES',
  //   disabled: true,
  //   children: [
  //     {
  //       title: 'Giriş Adetleri',
  //       route: 'Services',
  //       resource: 'RP_SERVICES',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Ciro',
  //       route: 'ServicePayment',
  //       resource: 'RP_SERVICES',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Araç Başı Ciro',
  //       route: 'CarPayment',
  //       resource: 'RP_SERVICES',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Aksesuar Satışı',
  //       route: 'AccessorySale',
  //       resource: 'RP_SERVICES',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Brüt Kar',
  //       route: 'GrossProfit',
  //       resource: 'RP_SERVICES',
  //       action: 'read',
  //     },
  //   ],
  //   action: 'read',
  // },
  {
    title: 'Müşteri Deneyimi',
    icon: 'PhoneCallIcon',
    resource: 'RP_CRM',
    children: [
      {
        title: 'Genel Bakış',
        route: 'CrmOverviews',
        resource: 'RP_CRM',
        action: 'read',
      },
      {
        title: 'Satış Şikayetleri',
        route: 'CrmSales',
        resource: 'RP_CRM',
        action: 'read',
      },
      {
        title: 'Servis Şikayetleri',
        route: 'CrmServices',
        resource: 'RP_CRM',
        action: 'read',
      },
      {
        title: 'Filo Şikayetleri',
        route: 'CrmRentals',
        resource: 'RP_CRM',
        action: 'read',
      },
      {
        title: 'İkinci El Şikayetleri',
        route: 'CrmI2',
        resource: 'RP_CRM',
        action: 'read',
      },
      // {
      //   title: 'CSI',
      //   route: 'CrmCsi',
      //   resource: 'RP_CRM',
      //   action: 'read',
      //   disabled: true,
      // },
      // {
      //   title: 'Gizli Müşteri',
      //   route: 'CrmMysteryShopper',
      //   resource: 'RP_CRM',
      //   action: 'read',
      //   disabled: true,
      // },
      {
        title: 'En Çok Başvuru Konusu',
        route: 'CrmSubjects',
        resource: 'RP_CRM',
        action: 'read',
      },
      // {
      //   title: 'Google Harita Puanları',
      //   route: 'CrmGoogleMaps',
      //   resource: 'RP_CRM',
      //   action: 'read',
      //   disabled: true,
      // },
    ],
    action: 'read',
  },
  {
    title: 'Sigorta',
    icon: 'LifeBuoyIcon',
    resource: 'RP_INSURANCE',
    children: [
      {
        title: 'Sıfır Satış Anlık',
        route: 'SalesInsurance',
        resource: 'RP_INSURANCE',
        action: 'read',
      },
      {
        title: 'Sıfır Satış Kümülatif',
        route: 'SalesInsuranceCumulatives',
        resource: 'RP_INSURANCE',
        action: 'read',
      },
      // {
      //   title: '2. El Satış Anlık',
      //   route: 'SecondHandInsurance',
      //   resource: 'RP_INSURANCE',
      //   action: 'read',
      //   disabled: true,
      // },
      // {
      //   title: '2. El Satış Kümülatif',
      //   route: 'SecondHandInsuranceCumulatives',
      //   resource: 'RP_INSURANCE',
      //   action: 'read',
      //   disabled: true,
      // },
      {
        title: 'Yenileme',
        route: 'RenewalInsurance',
        resource: 'RP_INSURANCE',
        action: 'read',
      },
      {
        title: 'Branş Bazlı Ciro',
        route: 'BranchesInsurance',
        resource: 'RP_INSURANCE',
        action: 'read',
      },
      {
        title: 'Üretim ve Komisyon',
        route: 'ProductionInsurance',
        resource: 'RP_INSURANCE',
        action: 'read',
      },
    ],
    action: 'read',
  },
]
